<template>
  <div id="lightbox-container" class="d-flex h-100 w-100 position-fixed justify-content-between">
    <div class="bg-light h-100 gallery-container" v-if="showGallery">
        <!-- <div class="d-flex justify-content-between">
            <div class="p-2 w-50 text-center">
                Interior
            </div>
            <div class="p-2 w-50 text-center">
                Exterior
            </div>
        </div> -->
        <div class="h-100 overflow-auto">
            <div v-for="(slide, i) in images" :key="i">
                <div class="p-2" :class="activeImage === i ? 'bg-primary' : ''">
                    <img class="w-100 cursor-pointer" :src="slide.i_Link" @click="() => {$emit('setImage', i); displayGallery()}"/> 
                </div>
            </div>
        </div>
    </div>
    <div class="flex-column justify-content-between w-100" :class="showGallery ? 'd-none d-md-flex' : 'd-flex'">
        <div class="text-right">
            <Close class="btn text-white" :size="48" @click="$emit('close')"/>
        </div>
        <div class="d-none d-md-flex justify-content-center selected-image-section">
            <!-- Desktop -->
            <button class="bg-primary text-white btn rounded-circle position-relative p-2 mr-3" style="height: fit-content" :style="'top:48%;'" @click="handleArrowClick(-1)">
                <ChevronLeft :size="38"/>
            </button>
            <div class="w-100 h-100">
                <div class="d-flex justify-content-center h-100">
                    <figure class="zoom" @mousemove="zoomImage" :style="`background-image: url(${images[activeImage].i_Link})`">
                        <img :src="images[activeImage].i_Link" :class="'cursor-zoom-in'">
                    </figure>
                </div>
                <div class="d-flex justify-space-between mt-4 align-items-baseline text-white">
                    <div>
                        {{ $t('Overview.viewingImage', [activeImage + 1, images.length]) }}
                    </div>
                    <div class="d-flex gap-2">
                        <div class="btn p-2 text-light border border-light" @click="copyURL($route.path)">
                            <ShareVariantOutline/>
                            {{
                                shareClicked ? $t('Overview.Copied') : $t('Overview.Share')
                            }}
                        </div>
                        <div class="btn p-2 text-light border border-light" @click="$emit('setShowGallery', !showGallery)">
                            <ImageMultipleOutline/>
                            {{
                                showGallery ? $t('Overview.hideGallery') : $t('Overview.viewGallery')
                            }}
                        </div>
                    </div>
                </div>
                <!-- <div class="text-center text-white">
                    <h4>
                        Image Name
                    </h4>
                </div> -->
            </div>
                    <button class="bg-primary text-white btn rounded-circle position-relative p-2 ml-3" style="height: fit-content" :style="'top:48%;'" @click="handleArrowClick(1)">
                        <ChevronRight :size="38"/>
                    </button>
        </div>
        <div class="d-block d-md-none w-100">
            <!-- Mobile -->
            <img :src="images[activeImage].i_Link" class="w-100" :class="'cursor-zoom-in'">
            <div class="d-flex justify-content-between position-relative mx-1" style="top:-50%;">
                <button class="bg-primary text-white btn rounded-circle p-1" style="opacity: 65%" @click="handleArrowClick(-1)">
                    <ChevronLeft/>
                </button>
                <button class="bg-primary text-white btn rounded-circle p-1" style="opacity: 65%" @click="handleArrowClick(1)">
                    <ChevronRight/>
                </button>
            </div>
        </div>
        <div class="">

            <div class="d-block d-md-none btn p-2 m-2 text-light border border-light" @click="copyURL($route.path)">
                <ShareVariantOutline/>
                            {{
                                shareClicked ? $t('Overview.Copied') : $t('Overview.Share')
                            }}
            </div>
            <div class="d-block d-md-none btn p-2 m-2 text-light border border-light" @click="$emit('setShowGallery', !showGallery)">
                <ImageMultipleOutline/>
                    {{
                        showGallery ? $t('Overview.hideGallery') : $t('Overview.viewGallery')
                    }}
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Close from 'vue-material-design-icons/Close.vue';
import ImageMultipleOutline from 'vue-material-design-icons/ImageMultipleOutline.vue';
import ShareVariantOutline from 'vue-material-design-icons/ShareVariantOutline.vue';
import ChevronLeft from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronRight from 'vue-material-design-icons/ChevronRight.vue';

export default {
    props: {
        images: {
            required: true
        },
        activeImage: {
            required: true,
        },
        showGallery: {
            required: false,
        }
    },
    components: {
        Close,
        ImageMultipleOutline,
        ShareVariantOutline,
        ChevronLeft,
        ChevronRight
    },
    data() {
        return {
            shareClicked: false
        }
    },
    methods: {
        async copyURL(myText) {
            try {
                let url = 
                    (process.env.NODE_ENV === "development" ? "http://localhost:8080/" : process.env.VUE_APP_BASE_URL_IMAGE) 
                    + myText.slice(1)
                    + "?g=true&i=" + this.activeImage;
                await navigator.clipboard.writeText(url);
                this.shareClicked = true;
            } catch($e) {
                alert('Cannot copy');
            }
        },
        displayGallery() {
                if (window.innerWidth < 992) {
                    this.$emit('setShowGallery', false)
                } else this.$emit('setShowGallery', !showGallery)
        },
        zoomImage(e) {
            let zoomer = e.currentTarget;
            let offsetX;
            let offsetY;
            let y;
            let x;
            e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
            e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX
            x = offsetX/zoomer.offsetWidth*100
            y = offsetY/zoomer.offsetHeight*100
            zoomer.style.backgroundPosition = x + '% ' + y + '%';
        },
        handleArrowClick(i) {
             let imageToView = this.activeImage + i;
             imageToView > this.images.length - 1 ? imageToView = 0 :
             imageToView < 0 ? imageToView = this.images.length - 1 :
             null;
            this.$emit('setImage', imageToView);
        }
    }
}
</script>

<style lang="scss" scoped>
.selected-image-section {
    height:60%;
    margin:0 auto;
    max-width:95%;
    
    max-height: 300px;

    @media screen and (min-width:768px) {
        height: 65%;
        max-height: 474px;
    }
    @media screen and (min-width:1264px) {
        height: 65%;
        max-height: 615px;
    }
    @media screen and (min-width:1500px) {
        height: 75%;
        max-height: 850px;
    }
}
figure.zoom {
  background-position: 50% 50%;
  position: relative;
  box-shadow: -1px 5px 15px black;
  height:inherit;
  /* width:auto; */
  overflow: hidden;
  cursor: zoom-in;
  background-size:200%;
    margin:0;
}

figure.zoom img {
  transition: opacity 0.5s;
  display: block;
  height: inherit;
  width:-webkit-fill-available;
  object-fit: cover;
}
figure.zoom img:hover {
  opacity: 0;
}
.gallery-container {
    width:100%;
    @media screen and (min-width:960px) {
        width:200px;
    }
    @media screen and (min-width:1264px) {
        width:275px;
    }
}
.lightboxImg-maxW {
    max-width: 1024px;
}
#lightbox-container {
    background-color:$dark;
    top:0; 
    left:0; 
    z-index:1000;
    /* ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none; */
}
</style>