<template>
  <div>
    <TheOverViewTab />
  </div>
</template>

<script>
import TheOverViewTab from "@/components/global/TheOverViewTab.vue";
export default {
  components: {
    TheOverViewTab,
  },

};
</script>
