var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.opened)?_c('LightBox',{attrs:{"images":_vm.sliders6,"showGallery":_vm.showGallery,"activeImage":_vm.activeImage},on:{"close":_vm.handleOpened,"setImage":(i) => _vm.activeImage=i,"setShowGallery":(bool) => _vm.showGallery = bool}}):_vm._e(),_c('div',{staticClass:"slider-wrapper"},[_c('div',{staticClass:"left-side"},[_c('div',{staticClass:"side_cover"}),_c('vueper-slides',{ref:"vehicleSlides_left",staticClass:"side_slide",attrs:{"touchable":false,"autoplay":false,"bullets":false,"arrows":false,"fixedHeight":"100%"}},_vm._l((_vm.sliders6),function(slide,i){return _c('vueper-slide',{key:i,attrs:{"image":slide.i_Link}})}),1)],1),_c('div',{staticClass:"main-side"},[_c('vueper-slides',{ref:"vehicleSlides",attrs:{"touchable":false,"autoplay":false,"bullets":false,"arrowsOutside":true,"slide-ratio":9 / 16},on:{"slide":function($event){_vm.$refs.vehicleSlides_left.goToSlide($event.currentSlide.index - 1, {
            emit: false,
          });
          _vm.$refs.vehicleSlides_right.goToSlide($event.currentSlide.index + 1, {
            emit: false,
          });
          _vm.$refs.vehicleSlides_2.goToSlide($event.currentSlide.index, {
            emit: false,
          });}}},_vm._l((_vm.sliders6),function(slide,i){return _c('vueper-slide',{key:i,attrs:{"image":slide.i_Link},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"w-100 h-100",on:{"click":()=> {_vm.handleOpened(true); _vm.activeImage=i}}})]},proxy:true}],null,true)})}),1)],1),_c('div',{staticClass:"right-side"},[_c('div',{staticClass:"side_cover"}),_c('vueper-slides',{ref:"vehicleSlides_right",staticClass:"side_slide",attrs:{"touchable":false,"autoplay":false,"bullets":false,"arrows":false,"fixedHeight":"100%"}},_vm._l((_vm.sliders6),function(slide,i){return _c('vueper-slide',{key:i,attrs:{"image":slide.i_Link}})}),1)],1)]),_c('div',{staticClass:"col-12 col-sm-8 mx-auto thumb-wrapper"},[_c('vueper-slides',{ref:"vehicleSlides_2",staticClass:"no-shadow thumbnails mx-auto",attrs:{"visible-slides":5,"slide-ratio":1 / 1,"bullets":false,"touchable":false,"arrows":false},on:{"slide":function($event){return _vm.$refs.vehicleSlides.goToSlide($event.currentSlide.index, {
          emit: false,
        })}}},_vm._l((_vm.sliders6),function(slide,i){return _c('vueper-slide',{key:i,attrs:{"image":slide.i_Link},nativeOn:{"click":function($event){_vm.$refs.vehicleSlides_2.goToSlide(i);
          _vm.$refs.vehicleSlides_left.goToSlide(i - 1);
          _vm.$refs.vehicleSlides_right.goToSlide(i + 1);}}})}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }