<template>
  <div class="py-3">
    <b-container class="mx-auto py-0">
      <b-link v-if="cardData && searchCountry" @click="$router.push({name: 'SearchResults', params: {country: searchCountry.Code, currency: cardData.Price.CurrencyCode}})">
          <b-icon icon="arrow-left" scale="1.5" class="mr-2" />
          {{ $t("listing.button.return") }}
        </b-link>
        <div style="background-color: #3cba54;" class="my-2 py-3 px-7 rounded text-white" v-if="isReserved">
          {{$t('Overview.AlreadyRSVP')}}
        </div>
    </b-container>
    <div id="printableOverview">
      <b-container class="mx-auto d-flex flex-column" v-if="cardData">
        <b-card class="mt-0 pb-lg-0 info-box" v-if="listing">
          <div class="car-make-wrapper">
            <div class="flex-fill">
              <h3 class="car-make d-flex align-items-center">
                {{ cardData.Year }} {{ cardData.Make }} {{ cardData.Model }}
                <div class="d-flex justify-content-end icon-wrapper">
                  <b-button
                    class="text-primary shadow-none px-0 d-flex align-items-center favorites-btn"
                    @click="setWishList()"
                  >
                    <HeartIcon v-if="cardData.IsWishList" fill-color="#DB4437" />
                    <HeartOutlineIcon v-if="!cardData.IsWishList" />
                  </b-button>
                        <a v-if="searchCountry && searchCountry.Code" 
                          :href="pdfURL"
                          class="text-primary shadow-none px-0 d-flex align-items-center favorites-btn ml-4">
                        <FilePdfBox :size="28"/>
                      </a>
                  <b-button
                    v-b-modal="'shareModal' + cardData.ListingId"
                    class="text-primary shadow-none px-0 d-flex align-items-center favorites-btn share-btn ml-4"
                  >
                    <ShareIcon />
                  </b-button>
                  <ShareModal :image="images[0]" v-if="cardData" :carDetail="cardData" />
                </div>
              </h3>
              <h3 class="car-mode">
                {{ cardData.Trim }}
              </h3>
            </div>
            <div class="car-props show-mobile">
              <h5 class="d-inline-block">
                {{ cardData.Kilometers }} {{ $t("Overview.KM") }}
              </h5>
              <b class="border mx-4"></b>
              <h5 class="d-inline-block text-dark">
                {{ cardData.Transmission }}
              </h5>
              <b class="border mx-4" />
              <h5 class="d-inline-block text-dark" v-if="cardData.Country">
                {{ cardData.Country }}
              </h5>
            </div>
            <div class="car-details">
              <h2 class="text-primary car-details-price">
                {{ 
                    isReserved && cardData.Price.TotalFeeId === 33 ? currencyFormatter(vehiclePrice, cardData.Price.CurrencyCode) :
                    isReserved && cardData.Price.TotalFeeId === 44 ? currencyFormatter(totalPrice, cardData.Price.CurrencyCode) :
                    !includeFees && vehiclePrice ? currencyFormatter(vehiclePrice, cardData.Price.CurrencyCode) : 
                    includeFees && totalPrice ? currencyFormatter(totalPrice, cardData.Price.CurrencyCode) :
                    priceData
                }}
              </h2>
              <p class="car-details-delivery mb-0">
                {{ $t("Overview.DeliveryIncluded") }} <br />
              </p>
              <div class="car-details-delivery">
                    <b-link v-if="!isReserved" @click="handleTotalFees(!includeFees)" class="mini-font text-primary"
                      >(change)</b-link>
                      <span v-if="(isReserved && cardData.Price.TotalFeeId === 44)">
                        <!-- {{$t('Overview.fees.included')}} -->
                        {{$t("carcard.help.including")}}
                      </span>
                      <span v-else-if="(isReserved && cardData.Price.TotalFeeId === 33)">
                        <!-- {{$t('Overview.fees.included')}} -->
                        {{  $t("carcard.help.delivery")}}
                      </span>
                      <span v-else-if="includeFees">
                        <!-- {{$t('Overview.fees.included')}} -->
                        {{$t("carcard.help.including")}}
                      </span>
                      <span v-else>
                        <!-- {{$t('Overview.fees.excluded')}} -->
                        {{  $t("carcard.help.delivery")}}
                      </span>
              </div>
              <b-link @click="scrollPrice()" class="car-details-link">
                {{ $t("Overview.PricingDetails") }}
              </b-link>
            </div>
          </div>
          <div class="car-details-wrapper">
            <div class="car-props-wrapper">
              <div class="car-props hide-mobile">
                <h5 class="d-inline-block">
                  {{ cardData.Kilometers }} {{ $t("Overview.KM") }}
                </h5>
                <b class="border mx-4"></b>
                <h5 class="d-inline-block text-dark">
                  {{ cardData.Transmission }}
                </h5>
                <b class="border mx-4"></b>
                <h5 class="d-inline-block text-dark" v-if="cardData.Country">
                  {{ cardData.Country }}
                </h5>
              </div>
            
              <div class="estimated-delivery">
                <p class="d-inline-block mb-0">
                  {{ $t("Overview.EstimatedDelivery") }} :
                </p>
                <b-form-select
                  style="margin-bottom: 3px"
                  class="border-0 px-1 p-0 w-auto shadow-none font-weight-bold text-primary"
                  size="sm"
                  :value="searchCountry"
                  @change="setCountry"
                  v-if="activeBuyerCountries"
                  disabled
                >
                  <b-form-select-option
                    v-for="country in activeBuyerCountries"
                    :value="country"
                    :key="country.Code"
                  >
                    {{ country.Name }}
                  </b-form-select-option>
                </b-form-select>
              </div>
              <div class="delivery-date">{{ getDeliveryDate }}</div>
            </div>
            <!-- BEGIN:Action Buttons -->
            <div class="text-right align-self-end">
              <!-- Reserve Button -->
              <b-button
                v-if="isConfirmed == false && isReserved == false"
                class="reserve-button"
                :to="{ name: 'Reservation', params: { id: id } }"
              >
                <b>{{ $t("Overview.ReserveNow") }}</b>
              </b-button>
            
              <!-- Reserved Button -->
              <b-button
                id="reservedBtn"
                v-else-if="isReserved == true && isConfirmed == false"
                disabled
                class="px-5 rounded-pill"
              >
                <b>{{ $t("Overview.Reserved") }}</b>
                <!-- <b-tooltip
                  target="reservedBtn"
                  triggers="hover"
                  placement="bottom"
                  variant="dark"
                >
                  <span>
                    {{ $t("Overview.ReservedTooltip") }}
                  </span>
                </b-tooltip> -->
              </b-button>
            
              <!-- Sold Button -->
              <b-button
                v-else-if="isConfirmed == true"
                pill
                disabled
                variant="outline-danger"
                class="px-5"
                id="soldBtn"
              >
                <b>{{ $t("Overview.Sold") }}</b>
              </b-button>
              <b-tooltip
                target="soldBtn"
                triggers="hover"
                placement="bottom"
                variant="dark"
              >
                <span>
                  {{ $t("Overview.SoldTooltip") }}
                </span>
              </b-tooltip>
            </div>
            <!-- END:Action Buttons -->
          </div>
        </b-card>
        <b-tooltip
          target="deliveryIncluded"
          triggers="hover"
          variant="primary"
          placement="left"
        >
          <h6>{{ $t("Overview.whyCantIChangeTheCountryTooltipTitle") }}</h6>
          <p>
            {{ $t("Overview.whyCantIChangeTheCountryTooltipText") }}
          </p>
        </b-tooltip>
      
        <b-tooltip
          target="arriveTime"
          triggers="hover"
          variant="primary"
          placement="right"
        >
          <p>
            {{ $t("Overview.whyCantIChangeTheCountryDeliveryTimeTooltipText") }}
          </p>
        </b-tooltip>
      
        <!-- {{carInfo}} -->
      
        <b-card class="mb-4 pb-lg-0 slider-box">
          <TheSlider
            :slides="images"
            :isReserved="isReserved"
            :isConfirmed="isConfirmed"
          />
        </b-card>
      
        <TheOverViewDetail :id="id" :listing="listing" :carInfo="carInfo" />
      
        <b-card class="reserve-card">
          <h3 class="reserve-card-title">
            {{ $t("Overview.ReserveWithConfidence") }}
          </h3>
          <b-row>
            <b-col cols="12" lg="4" class="reserve-card-item">
              <img
                class="reserve-card-item-icon"
                src="../../assets/img/reserve-confidence/item1.svg"
              />
              <h5 class="reserve-card-item-title">
                {{ $t("Overview.InspectedVehicleAndReadyForDrivingGuaranteed") }}
              </h5>
            </b-col>
            <b-col cols="12" lg="4" class="reserve-card-item">
              <img
                class="reserve-card-item-icon"
                src="../../assets/img/reserve-confidence/item2.svg"
              />
              <h5 class="reserve-card-item-title">
                {{ $t("Overview.DeliveredToYouInYourCountryNoHassle") }}
              </h5>
            </b-col>
            <b-col cols="12" lg="4" class="reserve-card-item">
              <img
                class="reserve-card-item-icon"
                src="../../assets/img/reserve-confidence/item3.svg"
              />
              <h5 class="reserve-card-item-title">
                {{ $t("Overview.ALimitedWarrantyCoverageForEveryVehicle") }}
              </h5>
            </b-col>
          </b-row>
        </b-card>
      
        <b-row class="settlement-holder">
          <b-col cols="12" lg="6">
            <div class="assistant-links-card">
              <a href="#" @click.prevent.stop="modalContent = $t('Overview.ReservationPolicy')" v-b-modal.gridModal>
                <span>
                  <img src="@/assets/img/icons/policy.svg" alt="" />
                </span>
                {{ $t("Overview.ReservationPolicy") }}</a
              >
              <a href="#" @click.prevent.stop="modalContent = $t('Overview.PashGuarantee')" v-b-modal.gridModal>
                <span>
                  <img src="@/assets/img/icons/guarantee.svg" alt="" />
                </span>
                {{ $t("Overview.PashGuarantee") }}</a
              >
              <a href="#" @click.prevent.stop="modalContent = $t('Overview.DeliveryPolicy')" v-b-modal.gridModal>
                <span>
                  <img
                    src="@/assets/img/icons/delivery-policy.svg"
                    alt=""
                  /> </span
                >{{ $t("Overview.DeliveryPolicy") }}</a
              >
            </div>
            <b-card class="assistant-card">
              <div class="assistant-card-holder">
                <h3 class="assistant-card-title">
                  {{ $t("Overview.NeedAssistance") }}
                </h3>
                <p class="assistant-card-text">
                  {{ $t("Overview.NeedAssistanceText") }}
                </p>
              </div>
              <div class="assistant-card-action">
                <!-- <div class="assistant-card-action-desc">
                  {{ $t("Overview.NeedAssistanceTalkDesc") }}
                </div> -->
                <div class="flex-column flex-sm-row gap-2 assistant-card-action-button-holder">
                  <b-button class="assistant-card-action-button chat w-100"
                    :href="'https://wa.me/' + countryContactDetails[searchCountry.Code].whatsapp"
                    target="_blank"
                  >
                    <img
                      src="@/assets/img/icons/whatsapp.svg"
                      class="assistant-card-action-button-icon"
                      alt=""
                    />
                    <span class="assistant-card-action-button-text">
                      {{ $t("Overview.Whatsapp") }}
                    </span>
                  </b-button>
                  <b-button class="assistant-card-action-button chat w-100"
                    :href="'mailto: ' + countryContactDetails[searchCountry.Code].email"
                    target="_blank"
                    >
                    <img
                      src="@/assets/img/icons/chat.svg"
                      class="assistant-card-action-button-icon"
                      alt=""
                    />
                    <span class="assistant-card-action-button-text">
                      {{ $t("Overview.LiveChat") }}
                    </span>
                  </b-button>
                  <b-button
                    class="assistant-card-action-button w-100"
                    :href="'tel: ' + countryContactDetails[searchCountry.Code].phone"
                  >
                    <img
                      src="@/assets/img/icons/headset.svg"
                      class="assistant-card-action-button-icon"
                      alt=""
                    />
                    <span class="assistant-card-action-button-text">
                      {{ represPhone ? represPhone : $t("Overview.CallUs") }}
                    </span>
                  </b-button>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col cols="12" lg="6" class="has-order">
            <b-card class="benefits-card benefits" id="priceDetails">
              <div class="benefits-card-header">
                <div class="benefit-pricing">
                  <h2 class="benefits-card-header-title">
                    {{ $t("Overview.Pricing") }}
                  </h2>
                </div>
                <div
                  class="text-right pr-0 benefits-card-header-country-holder benefits-delivery"
                >
                  <p class="benefits-card-country">
                    {{ $t("Overview.BasedOnDelivery") }}-
                  </p>
                  <b-form-select
                    class="border-0 p-0 w-auto shadow-none text-primary"
                    size="sm"
                    :value="searchCountry"
                    @change="setCountry"
                    v-if="activeBuyerCountries"
                    disabled
                  >
                    <b-form-select-option
                      v-for="country in activeBuyerCountries"
                      :value="country"
                      :key="country.Code"
                    >
                      {{ country.Name }}
                    </b-form-select-option>
                  </b-form-select>
                
                  <b-icon
                    icon="caret-down-fill"
                    class="pt-1 text-black"
                    scale="1"
                    style="margin-top: -3px"
                  />
                </div>
              </div>
              <div class="benefits-card-bill-holder">
                <div class="benefits-card-bill-item">
                  <div class="benefits-card-bill-item-title">
                    {{ $t("Overview.VehiclePrice") }}
                  </div>
                  <div
                    class="benefits-card-bill-item-value benefits-card-bill-item-price"
                  >
                    {{ 
                    currencyFormatter(vehiclePrice, cardData.Price.CurrencyCode) 
                    }}
                  </div>
                </div>
                <div class="benefits-card-bill-item">
                  <div class="benefits-card-bill-item-title" sm="12" lg="7">
                    {{ $t("Overview.InspectionFees") }}
                  </div>
                  <div class="benefits-card-bill-item-value">
                    {{ $t("Overview.Included") }}
                  </div>
                </div>
                <div class="benefits-card-bill-item">
                  <div class="benefits-card-bill-item-title" sm="12" lg="7">
                    {{ $t("Overview.ShippingAndDeliveryCharges") }}
                  </div>
                  <div class="benefits-card-bill-item-value">
                    {{ $t("Overview.Included") }}
                  </div>
                </div>
                <div class="benefits-card-bill-item">
                  <div class="benefits-card-bill-item-title" sm="12" lg="7">
                    {{ $t("Overview.30DaysLimitedWarranty") }}
                    <img src="@/assets/img/icons/information.svg" alt="" />
                  </div>
                  <b-tooltip
                    target="warranty"
                    triggers="hover"
                    variant="primary"
                    placement="right"
                  >
                    <h6>{{ $t("Overview.Warranty") }}</h6>
                    <p>
                      {{ $t("Overview.30DaysLimitedWarrantyTooltipText") }}
                    </p>
                  </b-tooltip>
                  <div class="benefits-card-bill-item-value">
                    {{ $t("Overview.Included") }}
                  </div>
                </div>
                <div class="benefits-card-bill-item">
                  <div class="benefits-card-bill-item-title" sm="12" lg="7">
                    {{ $t("Overview.CustomDutiesAndTaxes") }}
                    <img id="dutiesTaxes" src="@/assets/img/icons/information.svg" alt="" />
                  </div>
                  <b-tooltip
                    target="dutiesTaxes"
                    triggers="hover"
                    variant="primary"
                    placement="bottom"
                    class="text-left"
                  >
                    <!-- <h6>{{ $t("Overview.DutiesAndTaxes") }}</h6> -->
                    <p class="text-left mb-0 pb-0">
                      {{ $t("Overview.DutiesAndTaxesTooltipText") }}
                    </p>
                  </b-tooltip>
                  <div class="benefits-card-bill-item-value">
                    <b-link v-if="includeFees && !isReserved" @click="handleTotalFees(false)" class="mini-font text-primary"
                      >({{ $t("Overview.remove") }})</b-link>
                    <b-link v-if="!includeFees && !isReserved" @click="handleTotalFees(true)" class="mini-font text-primary"
                      >({{ $t("Overview.add") }})</b-link>
                      <span v-if="isReserved && cardData.Price.TotalFeeId === 44">
                        {{currencyFormatter(Math.abs(totalPrice - vehiclePrice), cardData.Price.CurrencyCode)}}
                      </span>
                      <span v-else-if="isReserved && cardData.Price.TotalFeeId === 33">
                        {{ $t("Overview.NotIncluded") }}
                      </span>
                      <span v-else-if="includeFees">
                        {{currencyFormatter(Math.abs(totalPrice - vehiclePrice), cardData.Price.CurrencyCode)}}
                      </span>
                      <span v-else>
                        {{ $t("Overview.NotIncluded") }}
                      </span>
                  </div>
                </div>
                <!-- <div class="border-top pt-3"></div>
                <b-row v-for="fees, key in $store.getters.listingFees" :key='key' v-show="fees.Name !== 'Total'">
                  <b-col sm="12" lg="7">{{fees.Name}}</b-col>
                  <b-col class="text-right">
                    <h5>{{ currencyFormatter(fees.Price.Amount, fees.Price.CurrencyCode) }}</h5>
                  </b-col>
                </b-row> -->
              </div>
              <div class="border-top fixed-button" id="bottom">
                <div class="d-flex justify-content-between Total-wrapper">
                  <div>
                    <div class="item">
                      {{ $t("Overview.TotalCost") }}
                    </div>
                  </div>
                  <div class="text-right">
                    <div class="item">
                      {{ 
                    isReserved && cardData.Price.TotalFeeId === 33 ? currencyFormatter(vehiclePrice, cardData.Price.CurrencyCode) :
                    isReserved && cardData.Price.TotalFeeId === 44 ? currencyFormatter(totalPrice, cardData.Price.CurrencyCode) :
                    !includeFees && vehiclePrice ? currencyFormatter(vehiclePrice, cardData.Price.CurrencyCode) : 
                    includeFees && totalPrice ? currencyFormatter(totalPrice, cardData.Price.CurrencyCode) :
                    priceData
                }}</div>
                  </div>
                </div>
                <div class="mt-1 benefits-card-bill-item-value text-right">
                  <!-- {{ $t("Overview.TotalDescription") }} -->
                  {{ $t("Overview.TotalCost") }}
                  <span style="text-transform:uppercase;">
                    {{  
                      isReserved && cardData.Price.TotalFeeId === 33 ? $t("Overview.excluding") :
                      isReserved && cardData.Price.TotalFeeId === 44 ? $t("Overview.including")  :
                      !includeFees && vehiclePrice ? $t("Overview.excluding") : 
                      includeFees && totalPrice ? $t("Overview.including") : 
                      $t("Overview.including") 
                    }}
                  </span>
                  {{ $t("Overview.DescCustomDutiesAndTaxes")}}
                </div>
              
                <!-- BEGIN:Action Buttons -->
                <b-row>
                  <!-- Reserve Now -->
                  <b-col cols="12" class="">
                    <b-button
                      pill
                      v-if="isConfirmed == false && isReserved == false"
                      size="lg"
                      variant="warning"
                      class="w-100 text-black reserve-btn"
                      :to="{ name: 'Reservation', params: { id: id } }"
                    >
                      {{ $t("Overview.ReserveNow") }}
                    </b-button>
                    <!-- Reserved Button -->
                    <b-button
                      id="reservedBtnBottom"
                      pill
                      v-else-if="isReserved == true && isConfirmed == false"
                      disabled
                      variant="secondary"
                      class="w-100 text-black reserve-btn"
                      size="lg"
                    >
                      {{ $t("Overview.Reserved") }}
                      <!-- <b-tooltip
                        target="reservedBtnBottom"
                        triggers="hover"
                        placement="bottom"
                        variant="dark"
                      >
                        <span>
                          {{ $t("Overview.ReservedTooltipText") }}
                        </span>
                      </b-tooltip> -->
                    </b-button>
                  
                    <!-- Sold Button -->
                    <b-button
                      v-else-if="isConfirmed == true"
                      pill
                      disabled
                      variant="outline-danger"
                      class="w-100 text-black reserve-btn"
                      size="lg"
                      id="soldBtnBottom"
                    >
                      {{ $t("Overview.Sold") }}
                    </b-button>
                    <b-tooltip
                      target="soldBtnBottom"
                      triggers="hover"
                      placement="bottom"
                      variant="dark"
                    >
                      <span>
                        {{ $t("Overview.vehicleSold") }}
                        This vehicle has been SOLD to another user. However you
                        may still choose to make this car a favorite, and you will
                        be notified if the vehicle becomes available.
                      </span>
                    </b-tooltip>
                  </b-col>
                </b-row>
                <!-- END:Action Buttons -->
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-modal hide-footer id="gridModal" :title='modalContent'>
          <div class="p-4">
            <div>{{ $t("global.label.comingsoon") }}</div>
          </div>
        </b-modal>
      
        <!-- {{ listing }}
        
        <p v-for="(l, key) in listing" :key="key">{{ l }}</p> -->
      </b-container>
      <div v-else>
        <b-row align-h="center" class="m-0">
          <b-spinner
            style="width: 10rem; height: 10rem; margin: 100px"
            variant="primary"
          />
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TheOverViewDetail from "@/components/global/TheOverViewDetail.vue";
import TheSlider from "@/components/global/TheSlider.vue";
import axios from "axios";
import HeartIcon from "vue-material-design-icons/Heart.vue";
import HeartOutlineIcon from "vue-material-design-icons/HeartOutline.vue";
import ShareIcon from "vue-material-design-icons/ShareVariantOutline.vue";
import printable from "@/components/mixins/printable"
import moment from "moment";
import ShareModal from "@/components/global/TheShareModal.vue";
import includeFees from '@/components/mixins/includeFees.js'
import listingTotals from '@/components/mixins/listingTotals.js'

export default {
  name: "OverView",
  components: {
    TheOverViewDetail,
    TheSlider,
    HeartIcon,
    ShareIcon,
    HeartOutlineIcon,
    ShareModal,
  },
  mixins: [printable, includeFees,listingTotals],
  data() {
    return {
      index: 0,
      id: this.$route.params.id,
      slide: 0,
      favorites: false,
      cancelFavorites: false,
      searchCountryInput: null,
      searchInput: "",
      arriveTime: null,
      isReserved: false,
      isConfirmed: false,
      prevRoute: null,
      heart: require("@/assets/img/fav_outline.svg"),
      heartFill: require("@/assets/img/fav_filled.svg"),
      information: require("@/assets/img/icons/information.svg"),
      represPhone: false,
      carInfo: null,
      modalContent: "",
      sharing: {
        url: location.href,
        title: ``,
        description: "check this car on the Pash",
        quote: "",
      },
      networks: [
        {
          network: "email",
          name: "Email",
          icon: "email",
          color: "red",
        },
        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "whatsapp",
          color: "green",
        },
      ],
      countryContactDetails: {
                SN: {
                    whatsapp: '+221773648423',
                    email: 'contact@pashmotors.com',
                    phone: '+221773648423'
                },
                CM: {
                    whatsapp: '+237699339234',
                    email: 'contact@pashmotors.com',
                    phone: '+237699339234'
                },
                CI: {
                    whatsapp: '+2250777051848',
                    email: 'contact@pashmotors.com',
                    phone: '+2250777051848'
                },
            }
    };
  },
  watch: {
    cardData(cardData) {
      if (cardData) {
        document.title = `${cardData.Year} ${cardData.Make} ${cardData.Model}`;
        this.sharing.title = `check ${cardData.Year} ${cardData.Make} ${cardData.Model} on the Pash`;
      }
    },
  },
  computed: {
    ...mapGetters(["activeBuyerCountries"]),
    getDeliveryDate() {
      if (this.listing) {
        return moment(this.listing.Delivery.DeliveryDate).format(
          "ddd DD MMM YYYY"
        );
      }
      return null;
    },
    pdfURL() {
      const queries = `&deliveryCountryCode=${this.searchCountry.Code}&currencyCode=${this.cardData.Price.CurrencyCode}&languageCode=${this.getLang()}&totalFeeId=${this.totalFeeId}`;
      const endpoint = this.isReserved ? `Reservation/PrintDetails?reservationId=${this.listing.ReservationId}` : `Listing/PrintDetails?listingId=${this.cardData.ListingId}`;
      return process.env.VUE_APP_CONSOLE_URL + endpoint + queries;
    },
    searchCountry() {
      let dest = null;
      if (this.$store.getters.destinationCountry) {
        return this.$store.getters.destinationCountry;
      } else if (dest) {
        return JSON.parse(dest);
      } else {
        this.$bvModal.show("selectDeliveryCountryCurrency");
      }
    },
    listing() {
      if (this.$store.getters.listing) {
        return this.$store.getters.listing;
      } else {
        return null;
      }
    },
    cardData() {
      if (this.listing) {
        return this.listing.Cardsdata;
      } else {
        return null;
      }
    },
    priceData() {
      let price = 0,
        currency = "CAD";
      if (this.cardData.Price) {
        const fee = this.cardData.Price;
        price = fee.Amount;
        currency = fee.CurrencyCode;
      }
      return this.currencyFormatter(price, currency);
    },
    images() {
      let img = [];
      if (this.listing.Cardsdata.Images) {
        this.listing.Cardsdata.Images.forEach((element) => {
          img.push({ i_Link: this.$store.getters.hostURL + element });
        });
      }
      for (let i = img.length; i < 5; i++)
        img.push({ i_Link: require("@/assets/img/nophoto.jpg") });
      return img;
    },
  },
  methods: {
    handleTotalFees(toInclude) {
      this.includeFees = toInclude;
      this.totalFeeId = axios.defaults.headers.common["defaultTotalFeeId"];
    },
    getLang() {
      return localStorage.getItem('locale');
    },
    async getCarInfo() {
      try {
        const response = await axios.post(
          // "SP/Post/sp_ListingV2_Details_Get"
          "Listing/CarInfo",
          {
            ListingId: this.id,
          }
        );
        this.carInfo = response.data;
      } catch (error) {
        error;
      }
    },
    findTag(id) {
      //Finds tags in carDetail by ID (ex: Engine: 5004)
      if (this.carDetail) {
        let found = this.carDetail.find((el) => el.ptd_TagId == id);
        if (found) return found.Value;
        else return null;
      } else return null;
    },
    scrollPrice() {
      document
        .getElementById("priceDetails")
        .scrollIntoView({ behavior: "smooth" });
    },
    currencyFormatter(value, currency) {
      return new Intl.NumberFormat(this.$store.getters.currencyCulture, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: value % 1 != 0 ? 2 : 0,
      }).format(value);
    },
    searchSubmit() {
      //Sets the search in the store and the calls the API
      if (this.searchCountryInput) {
        localStorage.setItem(
          "destCountry",
          JSON.stringify(this.searchCountryInput)
        );
        this.$store.dispatch("searchResult", this.searchInput);
        this.search();
      } else if (this.searchCountry) {
        this.$store.dispatch("searchResult", this.searchInput);
        this.search();
      } else {
        alert("You must select a valid vechicle destination country");
      }
    },
    setCountry(e) {
      this.searchCountryInput = e;
      this.$store.commit("destinationCountry", e);
      this.searchSubmit();
    },
    search() {
      this.$store.commit("listings", null);
      this.$store.commit("filters", null);
      this.$router.push({
        name: "SearchResults",
        params: { country: this.$store.getters.destinationCountryCode },
      });
    },
    setWishList() {
      if (this.$store.getters.user) {
        this.$store.dispatch("updateWishList", this.id);
        this.favorites = !this.favorites;
      } else {
        this.$bvModal.show("signin");
      }
    },
    async getArriveTime() {
      const response = await axios
        .post("SP/post/sp_DeliveryDetails", {
          DestCountryCode: this.searchCountry.Code,
          listingId: this.id,
        })
        .catch((error) => {
          console.log(error.response.data);
        });
      let d = JSON.parse(response.data.result).Table[0].DeliveryArriveDate;
      this.arriveTime = d ? d : " 6-12 weeks [no data received]";
    },
    returnButton() {
      //this.$router.push({ name: "SearchResults" });
      this.$router.back();
    },
    checkPhone() {
      if (this.$store.getters.country.Code === "CA") {
        this.represPhone = "1-438-888-8888";
      } else {
        this.represPhone = "1-800-667-9888";
      }
    },
    async handleScroll() {
      const element = document.getElementById("bottom");
      if (!element) return;
      if (window.innerWidth <= 767) {
        if (document.documentElement.scrollHeight - window.scrollY > 2320) {
          element.classList.add("fixed-button");
          return;
        } else if (
          document.documentElement.scrollHeight - window.scrollY <=
          2162
        ) {
          element.classList.remove("fixed-button");
          return;
        }
      }
    },
  },
  async created() {
    //Gets the listing based on the listingId
    this.$store.dispatch("clearListing");
    const listing = await this.$store.dispatch("getListing", {
      id: this.id,
    });
    this.getCarInfo();
    this.setTotals(listing.Cardsdata.Price.CurrencyCode);
  },
  beforeUpdate() {
    // this.getArriveTime();
    if (this.$store.getters.listing) {
      if (this.$store.getters.listing.IsWishList) this.favorites = true;
      if (this.$store.getters.listing.IsReserved) this.isReserved = true;
      if (this.$store.getters.listing.IsConfirmed) this.isConfirmed = true;
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from.name;
    });
  },
  beforeRouteLeave(to, form, next) {
    this.$store.dispatch("clearListing");
    next();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    document.title = `Pashmotors`;
  },
};
</script>

<style scoped lang="scss">
.reserve-btn {
  font-weight: 600;
  margin-top: 32px;
  @media (max-width: 767px) {
    font-weight: 500;
    font-size: 16px;
    margin-top: 16px;
  }
}
.card-body {
  padding: 15px !important;
  padding-bottom: 0 !important;
}
.custom-select {
  background: none !important;
  background-color: white !important;
  font-size: 16px;
  margin-bottom: 3px;
  color: $light-blue !important;
  font-weight: 600;
}
.info-box {
  margin-top: 24px;
  background: white;
  margin-bottom: 24px;
  @media (max-width: 767px) {
    margin-top: 0;
    margin-bottom: 16px;
  }
  .card-body {
    padding: 24px !important;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 4px;
    @media (max-width: 575px) {
      padding: 16px !important;
    }
    .car-mode {
      font-size: 24px;
      text-transform: uppercase;
      color: #232323;
      font-weight: 400;
      @media (max-width: 575px) {
        font-size: 18px;
      }
    }
    .car-details {
      text-align: right;
      @media (max-width: 767px) {
        text-align: left;
      }
      &-price {
        font-size: 28px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 5px;
      }
      &-delivery {
        font-size: 14px;
        color: #777;
        margin-top: 0px;
        margin-bottom: 8px;
        justify-content: end;
        white-space: nowrap;
        @media (max-width: 767px) {
          justify-content: start;
          margin-top: 16px;
          margin-bottom: 0;
        }
      }
      &-link {
        font-size: 15px;
      }
    }
    .car-props-wrapper {
      margin-top: -10px;
    }
    .car-props {
      position: relative;
      top: 10px;
      @media (max-width: 767px) {
        top: 0;
        margin-bottom: 16px;
        margin-top: 10px;
      }
      * {
        font-size: 20px;
        font-weight: 400;
        color: #232323;
        margin-bottom: 0;
        height: auto;
        @media (max-width: 767px) {
          font-size: 16px;
          font-weight: 600;
        }
      }
      b {
        border-color: #232323 !important;
        height: 16px;
        display: inline-block;
        @media (max-width: 575px) {
          margin: 0 8px !important;
        }
      }
    }
    .estimated-delivery {
      font-size: 16px;
      margin-top: 26px;
      font-weight: 600;
    }
    .delivery-date {
      font-weight: 600;
      margin-top: -5px;
    }
  }
  border: none;
}
.reserve-button {
  height: 42px;
  background: $yellow;
  border-radius: 42px;
  color: #1c1c1c !important;
  font-weight: 600;
  font-size: 16px;
  min-width: 180px;
  margin-top: 62px;
  border: none !important;
  padding: 0 16px;
  @media (max-width: 767px) {
    margin-top: 0;
    width: 100%;
    display: none;
  }
  b {
    font-size: 16px;
    color: #1c1c1c;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
.favorites-btn {
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 0;
  border: 1px solid #d0d0d0 !important;
  img {
    width: 100%;
    height: 100%;
    padding: 6px;
  }

  .favorites-icon {
    fill: #dadada;

    &-enable {
      fill: red;
    }
  }
}
.reserve-card {
  background: $light-blue;
  border-radius: 4px !important;
  margin-top: 24px;
  min-height: 245px;
  @media (max-width: 767px) {
    margin-top: 16px;
  }
  .card-body {
    padding: 24px !important;
    color: #fff;
  }
  &-title {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 24px;
    text-align: center;
    font-family: "Inter", sans-serif !important;
    @media (max-width: 991px) {
      margin-bottom: 16px;
    }
    @media (max-width: 575px) {
      font-size: 20px;
    }
  }
  &-item {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (max-width: 991px) {
      text-align: center;
      display: flex;
      align-items: center;
      column-gap: 20px;
      flex-direction: row;
    }
    &:not(:last-of-type) {
      @media (max-width: 991px) {
        margin-bottom: 24px;
      }
    }
    &-title {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 0;
      font-family: "Inter", sans-serif !important;
      @media (max-width: 991px) {
        text-align: left;
      }
    }
    &-icon {
      margin-bottom: 24px;
      width: 64px;
      height: 64px;
      @media (max-width: 991px) {
        margin-bottom: 0;
        width: 50px;
        height: 50px;
      }
    }
  }
}
.settlement-holder {
  margin-top: 40px;
  margin-bottom: 40px;
  @media (max-width: 767px) {
    margin-top: 16px;
  }
}
.benefits-card {
  border-radius: 10px;
  @media (max-width: 991px) {
    margin-bottom: 24px;
  }
  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
  .card-body {
    padding: 24px !important;
    @media (max-width: 575px) {
      padding: 16px !important;
    }
  }
  &-header {
    margin-bottom: 24px;
    border-bottom: 1px solid #a4a4a4;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 56px;
    @media (max-width: 575px) {
      margin-bottom: 16px;
      border-bottom: 1px solid #a4a4a4;
      height: auto;
      padding-bottom: 10px;
      flex-direction: column;
    }
    &-title {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 0;
    }
    &-country {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #404040;
    }
    &-country-holder {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @media (max-width: 575px) {
        margin-top: 8px;
      }
      p {
        margin-bottom: 0;
      }
    }
  }
  &-bill-item {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-title {
      font-size: 1rem;
      line-height: 1.25rem;
      color: #1c1c1c;
      font-family: "Inter", sans-serif;
      @media (max-width: 575px) {
        font-size: 14px;
      }
    }
    &-value {
      text-align: right;
      font-size: 1rem;
      line-height: 1.25rem;
      color: #777;
      @media (max-width: 575px) {
        font-size: 12px;
      }
    }
    &-price {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #1c1c1c;
      font-family: "Inter", sans-serif;
      @media (max-width: 575px) {
        font-size: 20px;
      }
    }
  }
}
.assistant-card {
  overflow: hidden;
  border-radius: 10px;
  background: #1c1c1c;
  color: #fff;
  .card-body {
    padding: 0 !important;
  }
  &-holder {
    padding: 24px;
    @media (max-width: 575px) {
      padding: 16px !important;
    }
  }
  &-title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 18px;
  }
  &-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  &-action {
    background-color: #232323;
    padding: 24px;
    @media (max-width: 575px) {
      padding: 16px !important;
    }
    &-desc {
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      display: block;
      margin-bottom: 24px;
    }
    &-button-holder {
      display: flex;
      align-items: center;
    }
    &-button {
      padding: 14px 20px;
      min-width: 154px;
      height: 52px;
      border: 1px solid #fff;
      border-radius: 4px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      transition: all 0.3s;
      @media (max-width: 575px) {
        width: 140px;
        min-width: auto;
        padding: 14px 10px;
      }
      &:hover {
        background-color: #fff;
        .assistant-card-action-button-text {
          color: #404040;
        }
        .assistant-card-action-button-icon {
          color: #404040;
        }
      }
      &-icon {
        margin-right: 16px;
        width: 24px;
        height: 22px;
      }
      &-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
.totalDescription {
  font-size: 14px;
  line-height: 20px;
  padding-top: 16px;
  @media (max-width: 991px) {
    text-align: center;
  }
}
h3 {
  font-size: 28px;
  line-height: 40px;
  margin-bottom: 5px;
}

.border-top {
  border-top: 1px solid #a4a4a4 !important;
  padding-top: 24px;
  margin-top: 24px;
  @media (max-width: 767px) {
    padding-top: 16px;
    margin-top: 16px;
  }
}
.btn-warning {
  b {
    color: #1c1c1c;
  }
}
.car-make-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  h3 {
    @media (max-width: 767px) {
      justify-content: space-between;
      font-size: 20px;
      line-height: 30px;
    }
  }
  > div:first-child {
    width:100%;
    @media screen and (min-width:992px) {
      width:unset;
    }
  }
}
.car-details-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 575px) {
  h3 {
    font-size: 24px;
  }
  .info-box .card-body .car-details-price {
    font-size: 24px;
  }
  .car-details-wrapper {
    flex-direction: column;
  }
  .info-box .card-body .estimated-delivery {
    margin-top: 8px;
  }
  .info-box .card-body .car-details-delivery {
    margin-top: 10px;
    justify-content: flex-start !important;
  }
  .info-box .card-body .car-props-wrapper {
    margin-top: 16px;
  }
  .info-box .card-body .car-details-link {
    display: block;
    width: 100%;
    text-align: left;
  }
  .car-details {
    margin-top: 10px;
  }
}
.has-order {
  @media (max-width: 991px) {
    order: -1;
  }
}
.icon-wrapper {
  margin-left: 32px !important;
  @media (max-width: 767px) {
    margin-left: 20px !important;
  }
}
.slider-box {
  @media (max-width: 767px) {
    order: -1;
  }
  .card-body {
    padding: 8px !important;
    @media (max-width: 767px) {
      padding: 0 !important;
    }
  }
}
.assistant-links-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  img {
    transition: 0.3s;
  }
  a {
    font-size: 18px;
    border-bottom: 1px solid #dbdbdb;
    transition: 0.3s;
    height: 56px;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: $light-blue;
    font-family: "Inter", sans-serif;
    &:hover {
      color: #065092 !important;
      img {
        transform: scale(1.05);
      }
    }
    span {
      margin-right: 16px;
    }
  }
}
.hide-mobile {
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
}
.show-mobile {
  display: none;
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
  }
}
.fixed-button {
  @media (max-width: 767px) {
    position: fixed;
    width: 100%;
    bottom: 0;
    background: white;
    z-index: 10;
    right: 0;
    box-shadow: 0px -5px 6px rgba(0, 0, 0, 0.06);
    /* border: none !important; */
    padding: 21px 16px 16px 16px;
    /* height: 90px; */
  }
  .Total-wrapper {
    @media (max-width: 767px) {
      /* display: none !important; */
    }
  }
  .totalDescription {
    @media (max-width: 767px) {
      /* display: none; */
    }
  }
  .btn {
    @media (max-width: 767px) {
      margin-top: 0 !important;
    }
  }
}
.Total-wrapper {
  .item {
    font-weight: 500;
    font-size: 24px;
    @media (max-width: 767px) {
      font-weight: 600 !important;
      font-size: 20px !important;
    }
  }
}
</style>
