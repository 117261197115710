import { mapGetters } from "vuex";
import axios from "axios";

export default {
    data() {
        return {
            totalFeeId: axios.defaults.headers.common["defaultTotalFeeId"],
            isReserved: null,
            vehiclePrice: null,
            totalPrice: null,
        }
    },
    computed: {
        ...mapGetters(["getTotalPrice", "user"]),
    },
    methods: {
        async validateReservation() {
            try {
                if (this.user) {
                    const response = await axios.post('Reservation/ReservationValidation', {
                        ListingId:this.$route.params.id
                    });
                    this.isReserved = false;
                    return response;
                }
            } catch (err) {
                this.isReserved = true;
            }
        },
        async setTotals(currencyCode) {
            try {
                const {data} = await this.getPricing(this.id || this.$route.params.id, currencyCode);
                data[0].Prices.forEach(priceData => {
                    if (priceData.TotalFeeId === 33) this.vehiclePrice = priceData.Amount;
                    if (priceData.TotalFeeId === 44) this.totalPrice = priceData.Amount;
                });
            } catch (error) {
                console.log(error);
            }
          },
          getCardData() {
            if (this.$store.getters.listing) {
                return this.$store.getters.listing.Cardsdata;
              } else {
                return null;
              }
          },
          getPricing(itemId, currencyCode) {
            return this.getTotalPrice(itemId, currencyCode);
          },      
    },
    async mounted() {
        await this.validateReservation()
    }
}