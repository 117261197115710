import FilePdfBox from "vue-material-design-icons/FilePdfBox.vue";

export default {
    components: {
        FilePdfBox
    },
    methods: {
        printWindow(divName) {
          var printContents = document.getElementById(divName).innerHTML;
          const node = document.createElement('div');
          node.classList.add('printContainer')
          document.getElementById('app').style.display = 'none';
          document.body.append(node)// add the div to body, and make the body aware of printing (we apply a set of css styles to the body to hide its contents)
          document.getElementsByClassName('printContainer')[0].innerHTML = printContents;
          setTimeout(function() {
              window.print(); // call print
              document.body.getElementsByClassName('printContainer')[0].remove()
              document.getElementById('app').style.display = 'block';
        }, 350);
        },
    }
}